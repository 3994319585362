export const getUserInfo = function () {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = userInfo ? JSON.parse(userInfo) : null

    // 无手机号用户直接下线重新登录
    if (userInfo && !userInfo.telNo) {
        clearUserInfo()
        return null
    }
    if (userInfo) {
        const status = parseInt(+new Date() - (userInfo.timeStamp || 0))
        if (status > 3 * 24 * 60 * 60 * 1000) {
            clearUserInfo()
            return null
        }
    }
    return userInfo
}

export const setUserInfo = function (info) {
    let userInfo = localStorage.getItem('userInfo') || JSON.stringify({})
    userInfo = { ...JSON.parse(userInfo), ...info, timeStamp: +new Date() }
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    return userInfo
}

export const clearUserInfo = function () {
    localStorage.removeItem('userInfo')
}

export const mAuthUserInfo = function (ticket) {
    clearUserInfo()
    const redirectUrl = encodeURIComponent(window.location.href)
    const url = `https://m.htjz.cc/wx-login/index.html?ticket=${ticket}&redirectUrl=${redirectUrl}`
    window.location.replace(url)
}